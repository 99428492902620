exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-firma-nda-js": () => import("./../../../src/pages/firma-nda.js" /* webpackChunkName: "component---src-pages-firma-nda-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspektor-nadzoru-inwestorskiego-js": () => import("./../../../src/pages/inspektor-nadzoru-inwestorskiego.js" /* webpackChunkName: "component---src-pages-inspektor-nadzoru-inwestorskiego-js" */),
  "component---src-pages-kierownik-budowy-zachodniopomorskie-js": () => import("./../../../src/pages/kierownik-budowy-zachodniopomorskie.js" /* webpackChunkName: "component---src-pages-kierownik-budowy-zachodniopomorskie-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nadzor-inwestorski-realizacje-zachodniopomorskie-js": () => import("./../../../src/pages/nadzor-inwestorski-realizacje-zachodniopomorskie.js" /* webpackChunkName: "component---src-pages-nadzor-inwestorski-realizacje-zachodniopomorskie-js" */),
  "component---src-pages-przeglad-okresowy-budynkow-js": () => import("./../../../src/pages/przeglad-okresowy-budynkow.js" /* webpackChunkName: "component---src-pages-przeglad-okresowy-budynkow-js" */),
  "component---src-pages-swiadectwo-charakterystyki-energetycznej-js": () => import("./../../../src/pages/swiadectwo-charakterystyki-energetycznej.js" /* webpackChunkName: "component---src-pages-swiadectwo-charakterystyki-energetycznej-js" */)
}

